import { useEffect, useState } from 'react';
import {
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  CustomerServiceFilled,
  CustomerServiceOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { message, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import * as actions from '../redux/actions/auth';

export default function Navigator() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [current, setCurrent] = useState('home');

  const [selectedCSKHLink, setSelectedCSKHLink] = useState(null);
  const [loadingCSKHLink, setLoadingCSKHLink] = useState(true);
  const [banks, setBanks] = useState({});
  const [currentRequest, setCurrentRequest] = useState({});

  const handleContactSupport = () => {
    if (selectedCSKHLink) {
      window.open(selectedCSKHLink, '_blank', 'noopener,noreferrer');
    } else {
      message.info('Đang tải liên kết hỗ trợ. Vui lòng thử lại sau.');
    }
    return;
  };

  useEffect(() => {
    //  const randomIndex = Math.floor(Math.random() * facebookSupportLinks.length);
    //  setSelectedCSKHLink(facebookSupportLinks[randomIndex]);
    //  setLoadingCSKHLink(false);
    loadProfile();
  }, []);

  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
    loadCurrentRequest();
    if (data.banks) {
      setBanks(data.banks);
    }
    if (data.data && data.data.sale) {
      setSelectedCSKHLink(data.data.sale.phone);
      localStorage.setItem('selectedLink', data.data.sale.phone);
      setLoadingCSKHLink(false);
    }
  };
  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get('/requests/lasted');
      setCurrentRequest(data?.data ? data.data : {});
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 60,
        padding: '5px 15px',
        borderTop: '1px solid #eee',
        boxShadow: '0 0 10px #eee',
        paddingBottom: 5,
        background: '#fff',
      }}
    >
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 65,
          minWidth: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={() => {
          setCurrent('wallet');
          history.push('/wallet');
        }}
      >
        <CreditCardOutlined
          style={{
            fontSize: 20,
            color: current == 'wallet' ? '#2b3c96' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            marginTop: 5,
            fontSize: 12,
            color: current == 'wallet' ? '#2b3c96' : '#666',
          }}
        >
          Ví tiền
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 65,
          minWidth: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/');
          setCurrent('home');
        }}
      >
        <HomeOutlined
          style={{
            fontSize: 20,
            color: current == 'home' ? '#2b3c96' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            marginTop: 5,
            fontSize: 12,
            color: current == 'home' ? '#2b3c96' : '#666',
          }}
        >
          Trang chủ
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 65,
          minWidth: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={
          profile?.kyc?.name
            ? () =>
                profile?.has_contract
                  ? handleContactSupport()
                  : message.info('Bạn chưa có khoản vay được tạo.')
            : () => message.info('Bạn chưa xác minh danh tính.')
        }
        disabled={loadingCSKHLink}
      >
        <CustomerServiceOutlined
          style={{
            fontSize: 20,
            color: '#666',
          }}
        />
        <Typography.Text
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            marginTop: 5,
            fontSize: 12,
            color: '#666',
          }}
        >
          CSKH
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 65,
          minWidth: 75,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/me');
          setCurrent('me');
        }}
      >
        <UserOutlined
          style={{
            fontSize: 20,
            color: current == 'me' ? '#2b3c96' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            marginTop: 5,
            fontSize: 12,
            color: current == 'me' ? '#2b3c96' : '#666',
          }}
        >
          Hồ sơ
        </Typography.Text>
      </motion.div>
    </div>
  );
}
